{
  "ws": {
    "calendar": {
      "days": [
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat",
        "Sun"
      ],
      "months": [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ]
    },
    "forms": {
      "file": {
        "choose": "Choose file",
        "selectImageSection": "Select image section",
        "dragAndDropHere": "Drag and drop here",
        "or": "or",
        "browseForFile": "Browse for file",
        "noFileSelected": "No file selected.",
        "allowedFileTypes": "Allowed file types: {{allowedTypes}}",
        "allFiles": "all",
        "allImages": "all image files",
        "maxFileSize": "Maximum file size: {{maxFileSize}} MB",
        "typeNotAllowed": "One or more file types are not allowed. Allowed file types are: {{allowedTypes}}",
        "sizeNotAllowed": "The file size of one or more files is too large. The maximum file size is {{maxFileSize}} MB.",
        "noAllowedTypesGiven": "No allowed file types given"
      },
      "files": {
        "dragAndDropHere": "Drag and drop here",
        "browseForFiles": "Browse for files",
        "noFilesSelected": "No files selected."
      },
      "select": {
        "filter": "Filter",
        "noResults": "No results found"
      },
      "validation": {
        "required": "{{label}} is required",
        "maxlength": "{{label}} cannot have more than {{requiredLength}} characters",
        "minlength": "{{label}} must have at least {{requiredLength}} characters",
        "max": "{{label}} can be a maximum of {{max}}",
        "min": "{{label}} must be at least {{min}}",
        "email": "Please enter a valid email address",
        "requiredTrue": "{{label}} has to be active",
        "matDatepickerMin": "Date must be after {{matDatepickerMin}}",
        "matDatepickerMax": "Date must be before {{matDatepickerMax}}",
        "dateFormat": "Date format is invalid",
        "pattern": "Format is invalid",
        "invalidUrlFormat": "Must begin with https:// and be a valid URL",
        "invalidPhoneFormat": "The area code must be entered separately",
        "invalidCardNumber": "Must be exactly 16 digits long",
        "invalidCardValidity": "Must correspond to the MM/YY format (e.g. 05/27)",
        "invalidWhiteSpaces": "Spaces and special characters are not allowed",
        "invalidIban": "IBAN not valid according to ISO 7064",
        "invalidBic": "BIC not valid",
        "invalidLat": "The number must be between -90 and 90",
        "invalidLon": "The number must be between -180 and 1800",
        "noSpaces": "Spaces are not allowed",
        "sameAs": "Must be the same as {{compareNameTranslation}}",
        "fromRange": "Must be less than or equal to {{compareNameTranslation}}",
        "toRange": "Must be greater than or equal to {{compareNameTranslation}",
        "duplicateValues": "This value already exists",
        "clickToAddValue": "Confirm input with Enter or click the icon.",
        "numbersOnly": "Only numbers are allowed here.",
        "mustBeAValidEmailDomain": "Must be a valid domain and must not contain http, https, www or the @ sign."
      },
      "placeholder": {
        "search": "Search..."
      },
      "placesAPI": {
        "noAPIKey": "No Google Places API key found. Please set an API key in the configuration.",
        "noPlaceError": "No place found. Please try again."
      },
      "speechRecognition": {
        "missingMicPermission": "The browser does not have permission to use the microphone. Please allow the browser to use the microphone and try again.",
        "alreadyListening": "The speech recognition is already listening."
      },
      "colorPicker": {
        "invalidHexCode": "Invalid hex code"
      },
      "textEditor": {
        "link": "Link",
        "insertLinkTitle": "Insert link",
        "editLinkTitle": "Edit link",
        "openInNewTab": "Open in new tab",
        "selectTextToLink": "Please select the text you want to link",
        "invalidUrl": "Invalid URL, link cannot be inserted",
        "notAllHtmlTagsAreClosed": "Not all HTML tags are closed.",
        "invalidHtmlTag": "Invalid HTML tag in use.",
        "tooltip": {
          "bold": "Bold",
          "italic": "Italic",
          "underline": "Underline",
          "strikeThrough": "Strikethrough",
          "blockquote": "Blockquote",
          "alignLeft": "Align left",
          "alignCenter": "Align center",
          "alignRight": "Align right",
          "alignJustify": "Align justify",
          "unorderedList": "Bullet list",
          "orderedList": "Numbered list",
          "link": "Link",
          "unlink": "Unlink",
          "insertImage": "Insert image",
          "insertEmoji": "Insert emoji",
          "removeFormat": "Remove format",
          "undo": "Undo",
          "redo": "Redo",
          "showHtml": "Show HTML code",
          "closeHtml": "Hide HTML code",
          "selectColor": "Select color",
          "useColor": "Use color",
          "backgroundColor": "Background color",
          "separator": "Insert separator",
          "indent": "Indent",
          "outdent": "Outdent",
          "superScript": "Superscript",
          "subScript": "Subscript",
          "remove": "Remove",
          "url": "URL",
          "text": "Text",
          "openInNewTab": "Open in new tab",
          "insert": "Insert"
        },
        "formattingOption": {
          "heading": "Heading",
          "p": "Text",
          "h1": "Heading 1",
          "h2": "Heading 2",
          "h3": "Heading 3",
          "h4": "Heading 4",
          "h5": "Heading 5",
          "h6": "Heading 6"
        },
        "selectCustomStyle": "Select style"
      },
      "input": {
        "copySuccessful": "Copied to clipboard"
      },
      "save": "save",
      "cancel": "cancel",
      "delete": "delete",
      "forExample": "e.g."
    },
    "module-view": {
      "element": "element",
      "elements": "elements",
      "selected": "selected",
      "elementsNotFound": "No {{ elementType }} found!",
      "profileTest": "Profile Picture",
      "noFilter": "No filter",
      "active": "Active",
      "inactive": "Inactive",
      "sorting": "Sort",
      "from": "from",
      "to": "to",
      "comparisonType": "Comparison type",
      "operator" : {
        "noComparison": "no comparison",
        "equals": "equals =",
        "greaterThan": "greater than >",
        "greaterThanEquals": "greater than equals >=",
        "lessThan": "less than <",
        "lessThanEquals": "less than equals <="
      },
      "sort": {
        "none": "None",
        "asc": "Ascending",
        "desc": "Descending"
      },
      "button": {
        "selectAll": "Select all",
        "clearSelection": "Clear selection",
        "search": "Search",
        "startSearch": "Start search",
        "cancelSearch": "Cancel",
        "resetSearch": "Reset search",
        "list-view": "List view",
        "module-view": "Module view",
        "listView": "List view",
        "cardView": "Card view",
        "configure": "Configure"
      },
      "sidebar": {
        "configuration": "Configuration"
      },
      "advancedSearch": {
        "fullTextSearch": "Full-text search",
        "multiTextSearch": "Multi-text search"
      },
      "pagination": {
        "itemsPerPageLabel": "Items per page",
        "nextPageLabel": "Next",
        "previousPageLabel": "Previous"
      },
      "checkInputs": "Please check your inputs!"
    },
    "dialog": {
      "confirm": "Confirm"
    },
    "sidebar": {
      "close": "Close"
    },
    "notifications": {
      "cancel": "Cancel",
      "confirm": "Ok"
    },
    "keys": {
      "control": {
        "a": "Ctrl + A"
      }
    },
    "stepper": {
      "next": "Next",
      "back": "Back",
      "finish": "Save"
    },
    "pdf": {
      "temporaryLinkFailed": "The temporary link to edit the template could not be generated."
    },
    "ws": {
      "update": {
        "appOutdated": "New version available",
        "clickToUpdate": "Click to update now",
        "message": "The app will have to reload in order to update to the new version. The update will be applied automatically after the next restart.",
        "title": "New version available",
        "updateLater": "Update on restart",
        "updateNow": "Update now"
      }
    }
  }
}
