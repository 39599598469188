import { Component } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { wsCoreLibraryTranslations } from '@ws-core/core-library'
import { PdfService } from './pdf.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'pdf'

  constructor(
    private translate: TranslateService,
    private pdfService: PdfService
  ) {
    this.translate.onLangChange.subscribe((langChangeEvent) => {
      this.translate.use(langChangeEvent.lang)
      document.documentElement.lang = langChangeEvent.lang
    })

    this.translate.setDefaultLang('de')

    const selectedLang = localStorage.getItem('ws|selectedLang')
    if (selectedLang) {
      this.translate.use(selectedLang)
    } else {
      const browserLang = this.translate.getBrowserLang()
      if (browserLang) {
        this.translate.use(browserLang)
      }
    }

    translate.setTranslation('en', wsCoreLibraryTranslations['en'])
    translate.setTranslation('de', wsCoreLibraryTranslations['de'])

    const url = new URL(window.location.href)
    const pdfTempKeyParamName = 'token'
    const tempApiKeyFromQueryParam = url.searchParams.get(pdfTempKeyParamName)

    const pdfUserIdParamName = 'user'
    const pdfUserIdFromQueryParam = url.searchParams.get(pdfUserIdParamName)

    if (tempApiKeyFromQueryParam) {
      this.pdfService.setTempApiKey(tempApiKeyFromQueryParam)
    }
    if (pdfUserIdFromQueryParam) {
      this.pdfService.setUserId(pdfUserIdFromQueryParam)
    }
  }
}
