{
  "ws": {
    "calendar": {
      "days": [
        "Mo",
        "Di",
        "Mi",
        "Do",
        "Fr",
        "Sa",
        "So"
      ],
      "months": [
        "Jänner",
        "Feber",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember"
      ]
    },
    "forms": {
      "file": {
        "choose": "Datei auswählen",
        "selectImageSection": "Bildausschnitt auswählen",
        "dragAndDropHere": "Datei hierher ziehen und ablegen",
        "or": "oder",
        "browseForFile": "Datei auswählen",
        "noFileSelected": "Keine Datei ausgewählt.",
        "allowedFileTypes": "Erlaubte Dateitypen: {{allowedTypes}}",
        "allFiles": "alle",
        "allImages": "alle Bilddateien",
        "maxFileSize": "Maximale Dateigröße: {{maxFileSize}} MB",
        "typeNotAllowed": "Ein oder mehrere Dateitypen sind nicht erlaubt. Erlaubte Dateitypen sind: {{allowedTypes}}",
        "sizeNotAllowed": "Die Dateigröße von ein oder mehreren Dateien ist zu groß. Die maximale Dateigröße beträgt {{maxFileSize}} MB.",
        "noAllowedTypesGiven": "Keine erlaubten Dateitypen angegeben"
      },
      "files": {
        "dragAndDropHere": "Dateien hierher ziehen und ablegen",
        "browseForFiles": "Dateien auswählen",
        "noFilesSelected": "Keine Dateien ausgewählt."
      },
      "select": {
        "filter": "Filter",
        "noResults": "Keine Ergebnisse"
      },
      "validation": {
        "required": "{{label}} wird benötigt",
        "maxlength": "{{label}} darf nicht mehr als {{requiredLength}} Zeichen lang sein",
        "minlength": "{{label}} muss mindestens {{requiredLength}} Zeichen lang sein",
        "max": "{{label}} darf maximal {{max}} sein",
        "min": "{{label}} muss mindestens {{min}} sein",
        "email": "Gib eine gültige E-Mail-Adresse ein",
        "requiredTrue": "{{label}} muss aktiviert sein",
        "matDatepickerMin": "Datum muss später als {{matDatepickerMin}} sein",
        "matDatepickerMax": "Datum muss früher als {{matDatepickerMax}} sein",
        "matDatepickerParse": "Das Format ist ungültig",
        "dateFormat": "Das Format ist ungültig",
        "pattern": "Das Format ist ungültig",
        "invalidUrlFormat": "Muss mit https:// beginnen und eine gültige URL sein",
        "invalidPhoneFormat": "Die Vorwahl muss separat angegeben werden",
        "invalidCardNumber": "Muss genau 16 Ziffern lang sein",
        "invalidCardValidity": "Muss dem Format MM/YY entsprechen (z.B. 05/27)",
        "invalidWhiteSpaces": "Leer- und Sonderzeichen sind nicht erlaubt",
        "invalidIban": "IBAN gemäß ISO 7064 ungültig",
        "invalidBic": "BIC ungültig",
        "invalidLat": "Die Zahl muss zwischen -90 und 90 liegen",
        "invalidLon": "Die Zahl muss zwischen -180 und 180 liegen",
        "noSpaces": "Keine Leerzeichen erlaubt",
        "sameAs": "Muss übereinstimmen mit {{compareNameTranslation}}",
        "fromRange": "Muss kleiner oder gleich {{compareNameTranslation}} sein",
        "toRange": "Muss größer oder gleich {{compareNameTranslation}} sein",
        "laterOrSimultaneously": "Muss später oder zur selben Zeit wie {{compareNameTranslation}} sein",
        "earlierOrSimultaneously": "Muss früher oder zur selben Zeit wie {{compareNameTranslation}} sein",
        "duplicateValues": "Wert bereits vorhanden",
        "clickToAddValue": "Eingabe mit Enter bestätigen oder auf das Icon klicken.",
        "numbersOnly": "Hier sind nur Zahlen erlaubt.",
        "mustBeAValidEmailDomain": "Muss eine gültige Domain sein und darf nicht http, https, www oder das @-Zeichen enthalten."
      },
      "placeholder": {
        "search": "Suche..."
      },
      "placesAPI": {
        "noAPIKey": "Kein API-Key für Google Places API gesetzt. Bitte setze einen API-Key in der Konfiguration.",
        "noPlaceError": "Konnte den Ort nicht finden. Bitte versuche es erneut."
      },
      "speechRecognition": {
        "missingMicPermission": "Die Spracherkennung benötigt Zugriff auf das Mikrofon. Bitte erlaube den Zugriff und versuchen Sie es erneut.",
        "alreadyListening": "Die Spracherkennung läuft bereits."
      },
      "colorPicker": {
        "invalidHexCode": "Ungültiger Hex-Code"
      },
      "textEditor": {
        "link": "Link",
        "insertLinkTitle": "Link einfügen",
        "editLinkTitle": "Link bearbeiten",
        "openInNewTab": "In neuem Tab öffnen",
        "selectTextToLink": "Bitte wähle den Text aus, der verlinkt werden soll.",
        "invalidUrl": "Ungültige URL, Link konnte nicht eingefügt werden.",
        "notAllHtmlTagsAreClosed": "Nicht alle HTML-Tags wurden geschlossen.",
        "invalidHtmlTag": "Ungültiges HTML-Tag in Verwendung.",
        "tooltip": {
          "bold": "Fett",
          "italic": "Kursiv",
          "underline": "Unterstrichen",
          "strikeThrough": "Durchgestrichen",
          "blockquote": "Zitat",
          "alignLeft": "Linksbündig",
          "alignCenter": "Zentriert",
          "alignRight": "Rechtsbündig",
          "alignJustify": "Blocksatz",
          "unorderedList": "Liste",
          "orderedList": "Nummerierte Liste",
          "link": "Link",
          "unlink": "Link entfernen",
          "insertImage": "Bild einfügen",
          "insertEmoji": "Emoji einfügen",
          "removeFormat": "Formatierung entfernen",
          "undo": "Rückgängig",
          "redo": "Wiederholen",
          "showHtml": "HTML Code anzeigen",
          "closeHtml": "HTML Code schließen",
          "selectColor": "Farbe auswählen",
          "useColor": "Farbe anwenden",
          "backgroundColor": "Hintergrundfarbe",
          "separator": "Trennstrich einfügen",
          "indent": "Einrücken",
          "outdent": "Ausrücken",
          "superScript": "Hochgestellt",
          "subScript": "Tiefgestellt",
          "remove": "Entfernen",
          "url": "URL",
          "text": "Text",
          "openInNewTab": "In neuem Tab öffnen",
          "insert": "Einfügen"
        },
        "formattingOption": {
          "heading": "Überschrift",
          "p": "Text",
          "h1": "Überschrift 1",
          "h2": "Überschrift 2",
          "h3": "Überschrift 3",
          "h4": "Überschrift 4",
          "h5": "Überschrift 5",
          "h6": "Überschrift 6"
        },
        "selectCustomStyle": "Stil auswählen"
      },
      "input": {
        "copySuccessful": "Inhalt wurde in die Zwischenablage kopiert"
      },
      "save": "speichern",
      "cancel": "abbrechen",
      "delete": "löschen",
      "forExample": "z.B."
    },
    "module-view": {
      "element": "Element",
      "elements": "Elemente",
      "selected": "ausgewählt",
      "elementsNotFound": "Keine {{ elementType }} gefunden!",
      "profileTest": "Profilbild",
      "noFilter": "Kein Filter",
      "active": "Aktiv",
      "inactive": "Inaktiv",
      "sorting": "Sortierung",
      "from": "von",
      "to": "bis",
      "comparisonType": "Vergleichstyp",
      "operator" : {
        "noComparison": "Kein Vergleich",
        "equals": "gleich =",
        "greaterThan": "größer >",
        "greaterThanEquals": "größer gleich >=",
        "lessThan": "kleiner <",
        "lessThanEquals": "kleiner gleich <="
      },
      "sort": {
        "none": "Keine",
        "asc": "Aufsteigend",
        "desc": "Absteigend"
      },
      "button": {
        "selectAll": "Alle auswählen",
        "clearSelection": "Auswahl aufheben",
        "search": "Suche",
        "startSearch": "Suche starten",
        "cancelSearch": "Abbrechen",
        "resetSearch": "Suche zurücksetzen",
        "list-view": "Listenansicht",
        "module-view": "Modulansicht",
        "listView": "Listenansicht",
        "cardView": "Modulansicht",
        "configure": "Konfigurieren"
      },
      "sidebar": {
        "configuration": "Konfiguration"
      },
      "advancedSearch": {
        "fullTextSearch": "Volltextsuche",
        "multiTextSearch": "Multitextsuche"
      },
      "pagination": {
        "itemsPerPageLabel": "Items pro Seite",
        "nextPageLabel": "Nächste",
        "previousPageLabel" : "Vorherige"
      },
      "checkInputs": "Bitte überprüfen Sie Ihre Eingaben!"
    },
    "dialog": {
      "confirm": "Übernehmen"
    },
    "sidebar": {
      "close": "Schließen"
    },
    "notifications": {
      "cancel": "Abbrechen",
      "confirm": "Ok"
    },
    "keys": {
      "control": {
        "a": "Strg + A"
      }
    },
    "stepper": {
      "next": "Weiter",
      "back": "Zurück",
      "finish": "Speichern"
    },
    "pdf": {
      "temporaryLinkFailed": "Link zum Bearbeiten des Templates konnte nicht erstellt werden."
    },
    "update": {
      "appOutdated": "Neue Version verfügbar",
      "clickToUpdate": "Klicken um jetzt zu aktualisieren",
      "message": "Die App sollte neu geladen werden um auf die neueste Version zu aktualisieren. Die Aktualisierung wird spätestens beim nächsten Start der App automatisch durchgeführt.",
      "title": "Neue Version erkannt",
      "updateLater": "Beim nächsten Start",
      "updateNow": "Jetzt aktualisieren"
    }
  }
}
