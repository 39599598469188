import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges,
  ViewChild
} from '@angular/core'
import { ControlValueAccessor, NgControl } from '@angular/forms'
import { OptionsFieldComponent } from '../options-field/options-field.component'
import { SelectOption } from '../../../types/forms/select-option'
import { MatSelect } from '@angular/material/select'
import { SpeechRecognitionResult } from '../../../types/forms/speech-recognition-result'
import { AbstractPlatformService } from '../../../types/module-view/abstract-platform-service'

@Component({
  selector: 'ws-form-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss', '../field/field.component.scss']
})
export class SelectComponent
  extends OptionsFieldComponent
  implements OnInit, ControlValueAccessor, OnChanges, OnDestroy
{
  @Input() public multiple = false
  @Input() public placeholder = ''
  @Input() public matOptionClass = ''
  @Input() public panelWidth = 'auto'
  @Input() public speechRecognitionIcon = 'mic'
  @Input() public autoFocusFilter = !this.platformService.isMobile
  @Input() public compareWithFunction?: (o1: any, o2: any) => boolean
  @Input() public override clearable = false
  @ViewChild('filterInput') public filterInput?: ElementRef
  @ViewChild('selectElement') private selectElement?: MatSelect
  @ViewChild('selectedOptions', { read: ElementRef }) private selectedOptionsTextElement?: ElementRef<HTMLDivElement>
  @Output() public valueChange = new EventEmitter<string | number>()
  @Output() public openedChange = new EventEmitter<boolean>()

  public search: string = ''
  public filteredOptions: SelectOption[] = []
  public selectedOptionsString: string = ''

  constructor(
    @Self()
    @Optional()
    public override ngControl: NgControl,
    @Inject('PlatformService') public override platformService: AbstractPlatformService
  ) {
    super(ngControl, platformService)
  }

  public override ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes)

    // updates filtered options if options change from @Input
    if (changes['options']) {
      this.setFilteredOptions(this.search)
    }
  }

  public override ngOnInit(): void {
    super.ngOnInit()
    this.setFilteredOptions(this.search)
    this.setSelectedOptionsString()
  }

  public override ngOnDestroy() {
    super.ngOnDestroy()
    this.speechRecognitionSubject?.unsubscribe()
  }

  public matchesFilter(option: SelectOption) {
    return !this.filteredOptions.includes(option)
  }

  public setFilteredOptions(value: string) {
    const searchInput = value.toLowerCase()
    // filters options depending on their label because that's what the user sees
    this.filteredOptions = this.options.filter((option) => option.label.toLowerCase().includes(searchInput))
  }

  public onSelectOpenedChange(open: boolean) {
    this.openedChange.emit(open)

    if (open) {
      if (this.autoFocusFilter) {
        this.filterInput?.nativeElement.focus()
      }
    } else {
      this.search = ''
      this.setFilteredOptions(this.search)
    }
  }

  public open() {
    this.selectElement?.open()
  }

  public close() {
    this.selectElement?.close()
  }

  public get panelOpen(): boolean {
    return !!this.selectElement?.panelOpen
  }

  public override touchStartSpeechRecognition() {
    if (this.platformService.isMobile) {
      this.toggleSelectSpeechRecognition(true)
    }
  }

  public override touchStopSpeechRecognition() {
    if (this.platformService.isMobile) {
      this.toggleSelectSpeechRecognition(false)
    }
  }

  public override toggleSpeechRecognition() {
    if (!this.platformService.isMobile) {
      if (this.listening) {
        this.toggleSelectSpeechRecognition(false)
      } else {
        this.toggleSelectSpeechRecognition(true)
      }
    }
  }

  public toggleSelectSpeechRecognition(listening: boolean) {
    if (!this.speechRecognition) {
      return
    }

    if (!this.listening && listening) {
      this.speechRecognitionSubject = this.speechRecognitionService.startSpeechRecognition(
        true,
        this.generateGrammarString()
      )
      this.listening = true
      this.speechRecognitionSubject.subscribe((result: SpeechRecognitionResult) => {
        if (result.type === 'final' || result.type === 'interim') {
          for (const option of this.options) {
            if (result.text.includes(option.label)) {
              this.listening = false
              this.speechRecognitionService.abortSpeechRecognition()
              this.value = option.value
              this.formControl.setValue(this.value)
              this.onFieldChange(this.value)
              this.close()
              break
            }
          }

          if (result.type === 'final') {
            this.listening = false
            this.speechRecognitionService.abortSpeechRecognition()
          }

          this.cdRef.detectChanges()
        } else if (result.type === 'error') {
          this.notificationService.createSnackBar(result.text, { severity: 'error' })
          this.listening = false
        }
      })
    } else {
      this.speechRecognitionService.abortSpeechRecognition()
      this.listening = false
    }
  }

  public compareObjects(o1: any, o2: any): boolean {
    return o1 === o2
  }

  public handleValueChange(value: any) {
    this.onFieldChange(value)
    this.valueChange.emit(value)
    this.setSelectedOptionsString()
  }

  private setSelectedOptionsString() {
    setTimeout(() => {
      this.selectedOptionsString = this.selectedOptionsTextElement?.nativeElement?.innerText || ''
    }, 100)
  }

  public handleClearIconClick(event: MouseEvent) {
    // prevent default click handling to make sure select does not open
    event.preventDefault()
    event.stopPropagation()

    this.clearInputValue()
  }
}
