<div id="wspdf-editor-editing-container">
  <!-- div containing all editor element buttons -->
  <div id="wspdf-editor-btn-wrapper" class="flex flex-row justify-between bg-primary p-4">
    <div id="wspdef-editor-btn-left-wrapper" class="pt-1">
      <div class="flex justify-start gap-4 items-center">
        <div>
          <img class="h-[55px] object-contain w-auto" src="/assets/icons/ws_icon.svg" alt="LOGO" />
        </div>

        <!-- History Dropdown -->
        <ws-button
          class="text-white"
          [flat]="true"
          [disabled]="loadingTemplateHistory"
          [matMenuTriggerFor]="formattingSelection"
        >
          <div class="flex items-center">
            <mat-icon class="mr-2">history</mat-icon>
            Frühere Versionen
            <mat-icon>arrow_drop_down</mat-icon>
          </div>
        </ws-button>
        <mat-menu class="max-w-none" #formattingSelection="matMenu">
          <button
            (click)="onHistoryVersionSelect(historyEntry)"
            mat-menu-item
            *ngFor="let historyEntry of templateHistory; let i = index"
          >
            <span class="flex items-center justify-between whitespace-nowrap">
              <mat-icon iconPositionEnd color="accent">restart_alt</mat-icon>
              <strong class="mr-4">v{{ templateHistory.length - i }}</strong>
              <span>{{ historyEntry.created | date: 'd.M.Y HH:mm' }}</span>
            </span>
          </button>
        </mat-menu>
      </div>
    </div>
    <div id="wspdf-editor-config-toggle" class="flex items-center">
      <!--        template overview
      <a href="/" class="mx-2 px-2" mat-button color="accent">
        <mat-icon>format_list_bulleted</mat-icon>
        {{ 'template.editor.templateOverview' | translate }}
      </a>-->
      <!-- edit test data -->
      <ws-button class="text-white" [flat]="true" (click)="openEditTestDataDialog()">
        <mat-icon class="mr-2">code</mat-icon>
        {{ 'template.editor.editTestData' | translate }}
      </ws-button>
      <!-- enable help grid -->
      <ws-button class="text-white" [flat]="true" (click)="toggleHelpingGrid()">
        <mat-icon class="mr-2">{{
          enableHelpingGrid === 'enable-helping-grid' ? 'visibility_off' : 'visibility'
        }}</mat-icon>
        {{ 'template.editor.helpingGrid' | translate }}
      </ws-button>
      <!-- display template preview in new window -->
      <ws-button class="text-white" [flat]="true" (click)="previewPdf()">
        <mat-icon class="mr-2">preview</mat-icon>
        {{ 'template.editor.preview' | translate }}
      </ws-button>
      <!-- undo redo-->
      <ws-button
        [disabled]="!wsPdfUndoRedoService.undoAvailable()"
        [class]="!wsPdfUndoRedoService.undoAvailable() ? 'opacity-0' : ''"
        class="text-white"
        [flat]="true"
        (click)="undo()"
      >
        <mat-icon>undo</mat-icon>
      </ws-button>
      <ws-button
        [disabled]="!wsPdfUndoRedoService.redoAvailable()"
        [class]="!wsPdfUndoRedoService.redoAvailable() ? 'opacity-0' : ''"
        class="text-white"
        [flat]="true"
        (click)="redo()"
      >
        <mat-icon>redo</mat-icon>
      </ws-button>
      <!-- show save icon to save template changes if selected template has been altered -->
      <ws-button
        [disabled]="!templateIsModified() || saving"
        class="save-template__button bg-accent text-white"
        (click)="saveTemplateChanges()"
      >
        {{ 'template.saveChanges' | translate }}
      </ws-button>
      <ws-button
        class="text-white"
        [flat]="true"
        *ngIf="!sidenav.opened"
        (click)="sidenav.toggle()"
        matTooltip="{{ 'template.configuration.open' | translate }}"
      >
        <mat-icon>first_page</mat-icon>
      </ws-button>
      <ws-button
        class="mx-2 px-2 text-white"
        [flat]="true"
        *ngIf="sidenav.opened"
        (click)="sidenav.toggle()"
        matTooltip="{{ 'template.configuration.hide' | translate }}"
      >
        <mat-icon>last_page</mat-icon>
      </ws-button>
    </div>
  </div>
  <mat-sidenav-container class="example-container flex flex-row justify-between">
    <mat-sidenav-content>
      <div id="wspdfEditorOuterContainer" [class]="enableHelpingGrid">
        <div class="wspdfEditorInnerContainer p-10 flex overflow-hidden">
          <div id="wspdef-editor-btn-middle-wrapper" class="bg-white rounded-xl mr-5">
            <div class="wspdf-editor-btn-middle p-1">
              <div id="wspdf-editing-add-btn-wrapper" class="flex items-center flex-col">
                <button
                  mat-icon-button
                  (click)="addReportComponentClicked(ComponentType.STATICLABEL)"
                  matTooltip="{{ 'template.editor.label' | translate }}"
                  matTooltipPosition="below"
                >
                  <mat-icon class="text-accent">text_fields</mat-icon>
                </button>
                <button
                  mat-icon-button
                  class="pl-2"
                  (click)="addReportComponentClicked(ComponentType.LABEL)"
                  matTooltip="{{ 'template.editor.placeholder' | translate }}"
                  matTooltipPosition="below"
                >
                  <mat-icon class="text-accent">data_object</mat-icon>
                </button>
                <button
                  mat-icon-button
                  (click)="openDatasetChoiceDialog()"
                  matTooltip="{{ 'template.editor.dataObject' | translate }}"
                  matTooltipPosition="below"
                >
                  <mat-icon class="text-accent">table_chart</mat-icon>
                </button>
                <button
                  mat-icon-button
                  (click)="addReportComponentClicked(ComponentType.IMAGE)"
                  matTooltip="{{ 'template.editor.image' | translate }}"
                  matTooltipPosition="below"
                >
                  <mat-icon class="text-accent">add_photo_alternate</mat-icon>
                </button>
                <!--
              <button mat-icon-button
                (click)="addReportComponent(ComponentType.SECTION)"
                matTooltip="{{ 'template.editor.container' | translate }}"
                matTooltipPosition="below"
              ><mat-icon>post_add</mat-icon>
              </button>
        -->
                <button
                  mat-icon-button
                  (click)="addReportComponentClicked(ComponentType.QRCODE)"
                  matTooltip="{{ 'template.editor.qrCode' | translate }}"
                  matTooltipPosition="below"
                >
                  <mat-icon class="text-accent">qr_code_2_add</mat-icon>
                </button>
                <button
                  mat-icon-button
                  (click)="addReportComponentClicked(ComponentType.STATICLABEL, true)"
                  matTooltip="{{ 'template.editor.pageCounter' | translate }}"
                  matTooltipPosition="below"
                >
                  <mat-icon class="text-accent">filter_1</mat-icon>
                </button>
                <!--    dialog explaining all usages of buttons and whole functionality of editor -->
                <!--        <button-->
                <!--          mat-icon-button-->
                <!--          (click)="openHelpDialog()"-->
                <!--          matTooltip="{{ 'template.help' | translate }}"-->
                <!--          matTooltipPosition="below"-->
                <!--        >-->
                <!--          <mat-icon>help</mat-icon>-->
                <!--        </button>-->
              </div>
            </div>
          </div>
          <div
            id="wspdfEditorDisplayContainer"
            #wspdfEditorDisplayContainer
            class="wspdf-editable-component boundary outline outline-gray-300 rounded-none m-auto"
          >
            <div id="wspdfEditorPageMarker" #wspdfEditorPageMarker>
              <header id="header" #pdfHeaderWrapperContainer style="position: absolute">
                <ng-container #pdfHeaderContainer></ng-container>
              </header>
              <div #wspdfEditorInnerPageMarker id="wspdfEditorInnerPageMarker" style="position: absolute; width: 100%">
                <div id="content-wrapper" #pdfContentWrapperContainer>
                  <ng-container #pdfPageContainer></ng-container>
                  <ng-container #pdfContentContainer></ng-container>
                </div>
              </div>
              <footer
                id="footer"
                #pdfFooterWrapperContainer
                [style.position]="'absolute'"
                [style.bottom]="'0px'"
                [style.left]="'0px'"
              >
                <ng-container #pdfFooterContainer></ng-container>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </mat-sidenav-content>
    <mat-sidenav
      #sidenav
      class="rounded sidenav-shadow"
      mode="side"
      position="end"
      [opened]="opened"
      (openedChange)="sideNavVisible($event)"
    >
      <div id="wspdfEditorElements" class="h-1/4">
        <div class="flex items-center p-4 bg-white border-b-2 border-accent justify-center align-center">
          <mat-icon class="example-tab-icon mr-2 text-accent">layers</mat-icon>
          {{ 'layers' | translate }}
        </div>
        <div id="elementList">
          <div
            *ngFor="let element of templateComponentsArray"
            (click)="layerElementClicked()"
            [class.bg-white]="currentSelectedElement?.instance?.id === element.instance.id"
            class="px-4 flex flex-row items-stretch justify-between text-sm cursor-pointer hover:bg-gray-100 transition-all duration-200 ease-in-out"
          >
            <div
              class="flex items-center break-words truncate hover:text-clip flex-grow"
              (click)="highlightElement(element.instance.id)"
            >
              {{ element.instance.id }}
            </div>
            <div class="flex items-center">
              <span
                class="pr-2 flex items-center"
                matTooltip="{{ 'template.element.zIndex' | translate }}"
                matTooltipPosition="below"
              >
                {{ element.instance.zIndex }}
              </span>
              <button
                mat-icon-button
                *ngIf="!element.instance.hidden"
                (click)="toggleElementVisibility(element)"
                class="px-1"
                matTooltip="{{ 'template.element.hide' | translate }}"
                matTooltipPosition="below"
              >
                <mat-icon class="text-accent">visibility</mat-icon>
              </button>
              <button
                mat-icon-button
                *ngIf="element.instance.hidden"
                (click)="toggleElementVisibility(element)"
                class="px-1"
                matTooltip="{{ 'template.element.show' | translate }}"
                matTooltipPosition="below"
              >
                <mat-icon class="text-accent">visibility_off</mat-icon>
              </button>
              <!--                  <button-->
              <!--                    mat-icon-button-->
              <!--                    (click)="copyElement(element.component)"-->
              <!--                    class="px-0"-->
              <!--                    matTooltip="{{ 'template.element.copy' | translate }}"-->
              <!--                    matTooltipPosition="below"-->
              <!--                  >-->
              <!--                    <mat-icon>content_copy</mat-icon>-->
              <!--                  </button>-->
              <button
                mat-icon-button
                (click)="removeElementClicked(element.instance.id)"
                class="px-1"
                matTooltip="{{ 'template.element.delete' | translate }}"
                matTooltipPosition="below"
              >
                <mat-icon class="text-accent">delete</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div (click)="wspdfEditorSettingsClicked()" id="wspdfEditorSettings" class="h-3/4 overflow-auto">
        <mat-tab-group [selectedIndex]="selectedTabIndex" (selectedIndexChange)="selectedTabIndex = $event">
          <mat-tab labelClass="flex items-center p-4 ">
            <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon mr-2 text-accent">settings</mat-icon>
              {{ 'template.settings.page' | translate }}
            </ng-template>
            <app-pdf-page-settings #pdfPageSettings></app-pdf-page-settings>
          </mat-tab>
          <mat-tab *ngIf="!!currentSelectedElement">
            <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon mr-2 text-accent">tune</mat-icon>
              {{ 'template.configuration.element' | translate }}
            </ng-template>
            <form class="p-4" id="element-settings" [formGroup]="elementSettingsForm">
              <h1 class="element-settings-title">{{ 'template.element.boundary' | translate }}</h1>
              <ws-form-select formControlName="elementBoundary" [options]="elementBoundaryOptions"></ws-form-select>
              <ws-form-input
                style="--ws-form-field-padding-top: 2em"
                *ngIf="currentSelectedElement.instance.type === ComponentType.QRCODE"
                label="{{ 'template.element.content' | translate }}"
                formControlName="elementQrCodeContent"
              ></ws-form-input>
              <ws-form-input
                style="--ws-form-field-padding-top: 2em"
                *ngIf="currentSelectedElement.instance.type === ComponentType.IMAGE"
                label="{{ 'template.element.imageUrl' | translate }}"
                formControlName="elementImageUrl"
              ></ws-form-input>
              <!-- alignment -->
              <div id="element-settings-align" class="mt-4">
                <h1 class="element-settings-title">{{ 'template.element.alignment' | translate }}</h1>
                <div class="grid grid-cols-2 gap-x-2 pt-2">
                  <span class="text-sm text-center font-bold">{{ 'template.element.horizontal' | translate }}</span>
                  <span class="text-sm text-center font-bold">{{ 'template.element.vertical' | translate }}</span>

                  <div id="element-settings-horizontal-align">
                    <button
                      mat-icon-button
                      (click)="elementSettingsForm.controls['elementHorizontalAlign'].setValue('left')"
                      class="ml-2"
                      matTooltip="{{ 'template.element.textAlignLeft' | translate }}"
                      matTooltipPosition="below"
                    >
                      <mat-icon>format_align_left</mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      (click)="elementSettingsForm.controls['elementHorizontalAlign'].setValue('center')"
                      class="ml-2"
                      matTooltip="{{ 'template.element.textAlignCenter' | translate }}"
                      matTooltipPosition="below"
                    >
                      <mat-icon>format_align_center</mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      (click)="elementSettingsForm.controls['elementHorizontalAlign'].setValue('right')"
                      class="ml-2"
                      matTooltip="{{ 'template.element.textAlignRight' | translate }}"
                      matTooltipPosition="below"
                    >
                      <mat-icon>format_align_right</mat-icon>
                    </button>
                  </div>
                  <div id="element-settings-vertical-align">
                    <button
                      mat-icon-button
                      (click)="elementSettingsForm.controls['elementVerticalAlign'].setValue('top')"
                      class="ml-2"
                      matTooltip="{{ 'template.element.textAlignTop' | translate }}"
                      matTooltipPosition="below"
                    >
                      <mat-icon>align_vertical_top</mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      (click)="elementSettingsForm.controls['elementVerticalAlign'].setValue('middle')"
                      class="ml-2"
                      matTooltip="{{ 'template.element.textAlignCenter' | translate }}"
                      matTooltipPosition="below"
                    >
                      <mat-icon>align_vertical_center</mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      (click)="elementSettingsForm.controls['elementVerticalAlign'].setValue('bottom')"
                      class="ml-2"
                      matTooltip="{{ 'template.element.textAlignBottom' | translate }}"
                      matTooltipPosition="below"
                    >
                      <mat-icon>align_vertical_bottom</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <!-- size -->
              <div id="element-settings-size" class="py-4">
                <h1 class="element-settings-title">{{ 'template.element.size' | translate }}</h1>
                <div class="grid grid-cols-4 gap-2">
                  <div class="text-left font-bold">{{ 'template.element.width' | translate }}:</div>
                  <div class="text-left">{{ elementSettingsForm.value.elementWidth | localeNumber }} cm</div>
                  <div class="text-left font-bold">{{ 'template.element.height' | translate }}:</div>
                  <div class="text-left">{{ elementSettingsForm.value.elementHeight | localeNumber }} cm</div>
                </div>
                <!--                <ws-form-input-->
                <!--                  label="{{ 'template.element.width' | translate }}"-->
                <!--                  formControlName="elementWidth"-->
                <!--                  (keyup)="elementWidthInputChanged($event)"-->
                <!--                ></ws-form-input>-->
                <!--                <ws-form-input-->
                <!--                  label="{{ 'template.element.height' | translate }}"-->
                <!--                  formControlName="elementHeight"-->
                <!--                  (keyup)="elementHeightInputChanged($event)"-->
                <!--                ></ws-form-input>-->
              </div>

              <div id="element-settings-position">
                <h1 class="element-settings-title">{{ 'template.element.position' | translate }}</h1>
                <div class="grid grid-cols-4 gap-2">
                  <div class="text-left font-bold">{{ 'template.element.horiz' | translate }}:</div>
                  <div class="text-left">
                    {{ elementSettingsForm.value.elementPositionHorizontal | localeNumber }} cm
                  </div>
                  <div class="text-left font-bold">{{ 'template.element.vert' | translate }}:</div>
                  <div class="text-left">{{ elementSettingsForm.value.elementPositionVertical | localeNumber }} cm</div>
                </div>
                <!--                <ws-form-input-->
                <!--                  label="{{ 'template.element.horizontal' | translate }}"-->
                <!--                  formControlName="elementPositionHorizontal"-->
                <!--                  type="number"-->
                <!--                ></ws-form-input>-->
                <!--                <ws-form-input-->
                <!--                  label="{{ 'template.element.vertical' | translate }}"-->
                <!--                  formControlName="elementPositionVertical"-->
                <!--                  type="number"-->
                <!--                ></ws-form-input>-->
              </div>

              <!-- visibility -->
              <div id="element-settings-visibility" class="py-4">
                <!--                <h1 class="element-settings-title">{{ 'template.element.visibility' | translate }}</h1>-->
                <!--              <div *ngIf="this.currentSelectedElement?.instance?.type !== ComponentType.IMAGE">-->
                <!--                <ws-form-input-->
                <!--                  type="color"-->
                <!--                  formControlName="elementBackgroundColor"-->
                <!--                  label="{{ 'template.element.backgroundColor' | translate }}"-->
                <!--                ></ws-form-input>-->
                <!--              </div>-->
                <!--                <ws-form-slider formControlName="elementOpacity"></ws-form-slider>-->
                <ws-form-input
                  label="{{ 'template.element.zIndex' | translate }}"
                  formControlName="elementZIndex"
                  type="number"
                >
                </ws-form-input>
                <!--              <div>-->
                <!--                <ws-form-checkbox-->
                <!--                  label="{{ 'template.element.show' | translate }}"-->
                <!--                  formControlName="elementVisibility"-->
                <!--                ></ws-form-checkbox>-->
                <!--              </div>-->
              </div>
              <!-- border -->
              <!--            <div id="element-settings-border">-->
              <!--              <h1 class="element-settings-title">{{ 'template.element.border' | translate }}:</h1>-->
              <!--              <mat-form-field>-->
              <!--                <mat-label>{{ 'template.element.border' | translate }}</mat-label>-->
              <!--                <mat-select formControlName="elementBorderStyle">-->
              <!--                  <mat-option value="none">{{ 'template.element.borderNone' | translate }}</mat-option>-->
              <!--                  <mat-option value="solid">___</mat-option>-->
              <!--                  <mat-option value="dashed">-&#45;&#45;</mat-option>-->
              <!--                  <mat-option value="dotted">...</mat-option>-->
              <!--                </mat-select>-->
              <!--              </mat-form-field>-->
              <!--              <div *ngIf="elementSettingsForm.controls['elementBorderStyle'].value !== 'none'">-->
              <!--                <mat-form-field *ngIf="elementSettingsForm.controls['elementBorderStyle'].value !== 'none'">-->
              <!--                  <mat-label>{{ 'template.element.width' | translate }}</mat-label>-->
              <!--                  <input matInput formControlName="elementBorderWidth" type="number" min="0" step="0.1" />-->
              <!--                  <span matTextSuffix>px</span>-->
              <!--                </mat-form-field>-->
              <!--                <mat-form-field>-->
              <!--                  <mat-label>{{ 'template.element.borderColor' | translate }}</mat-label>-->
              <!--                  <input matInput formControlName="elementBorderColor" type="color" />-->
              <!--                </mat-form-field>-->
              <!--              </div>-->
              <!--            </div>-->
              <!-- z-index -->
              <!-- position -->
            </form>
          </mat-tab>
          <!--    text_format / text_fields-->
          <mat-tab
            *ngIf="
              !!currentSelectedElement &&
              (currentSelectedElement.instance.type === ComponentType.STATICLABEL ||
                currentSelectedElement.instance.type === ComponentType.LABEL ||
                currentSelectedElement.instance.type === ComponentType.DATASET)
            "
          >
            <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon mr-2 text-accent">format_size</mat-icon>
              {{ 'template.configuration.textSettings' | translate }}
            </ng-template>
            <form class="p-4" id="element-text-settings" [formGroup]="elementTextSettingsForm">
              <!--              <div>{{ currentSelectedElement.instance.el.nativeElement.id }}</div>-->
              <!--              <ws-form-textarea label="{{ 'template.element.text' | translate }}" formControlName="elementText">-->
              <!--              </ws-form-textarea>-->

              <div id="element-text-settings-font">
                <h1 class="element-settings-title mb-4">{{ 'template.element.font' | translate }}</h1>
                <ws-form-select
                  label="{{ 'template.element.fontFamily' | translate }}"
                  formControlName="elementFont"
                  [options]="fontOptions"
                ></ws-form-select>
                <ws-form-input
                  style="--ws-form-field-padding-top: 2em"
                  formControlName="elementFontSize"
                  type="number"
                  suffix="px"
                  label="{{ 'template.element.fontSize' | translate }}"
                >
                </ws-form-input>

                <div id="element-text-settings-font-style" class="text-center py-4">
                  <button
                    *ngIf="currentSelectedElement.instance.type !== ComponentType.DATASET"
                    mat-icon-button
                    (click)="
                      elementTextSettingsForm.controls['elementFontWeight'].setValue(
                        elementTextSettingsForm.controls['elementFontWeight'].value === 'normal' ? 'bold' : 'normal'
                      )
                    "
                    class="ml-2"
                    matTooltip="{{ 'template.element.fontBold' | translate }}"
                    matTooltipPosition="below"
                  >
                    <mat-icon>format_bold</mat-icon>
                  </button>
                  <button
                    *ngIf="currentSelectedElement.instance.type !== ComponentType.DATASET"
                    mat-icon-button
                    (click)="
                      elementTextSettingsForm.controls['elementFontStyle'].setValue(
                        elementTextSettingsForm.controls['elementFontStyle'].value === 'normal' ? 'italic' : 'normal'
                      )
                    "
                    class="ml-2"
                    matTooltip="{{ 'template.element.fontItalic' | translate }}"
                    matTooltipPosition="below"
                  >
                    <mat-icon>format_italic</mat-icon>
                  </button>
                  <button
                    *ngIf="currentSelectedElement.instance.type !== ComponentType.DATASET"
                    mat-icon-button
                    (click)="
                      elementTextSettingsForm.controls['elementTextUnderline'].setValue(
                        elementTextSettingsForm.controls['elementTextUnderline'].value === 'underline'
                          ? 'none'
                          : 'underline'
                      )
                    "
                    class="ml-2"
                    matTooltip="{{ 'template.element.textUnderline' | translate }}"
                    matTooltipPosition="below"
                  >
                    <mat-icon>format_underlined</mat-icon>
                  </button>
                </div>
                <ws-form-color-picker
                  formControlName="elementFontColor"
                  label="{{ 'template.element.fontColor' | translate }}"
                ></ws-form-color-picker>
                <ws-form-button-toggle
                  *ngIf="currentSelectedElement.instance.type !== ComponentType.DATASET"
                  formControlName="elementTextTransform"
                  [options]="textTransformOptions"
                ></ws-form-button-toggle>
              </div>
              <div id="element-text-settings-text" style="--ws-form-field-padding-top: 2em" class="py-4">
                <h1 class="element-settings-title">{{ 'template.element.textAlign' | translate }}</h1>
                <div
                  *ngIf="currentSelectedElement.instance.type !== ComponentType.DATASET"
                  id="element-text-settings-align"
                >
                  <button
                    mat-icon-button
                    (click)="elementTextSettingsForm.controls['elementTextAlign'].setValue('left')"
                    class="ml-2"
                    matTooltip="{{ 'template.element.textAlignLeft' | translate }}"
                    matTooltipPosition="below"
                  >
                    <mat-icon>format_align_left</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    (click)="elementTextSettingsForm.controls['elementTextAlign'].setValue('center')"
                    class="ml-2"
                    matTooltip="{{ 'template.element.textAlignCenter' | translate }}"
                    matTooltipPosition="below"
                  >
                    <mat-icon>format_align_center</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    (click)="elementTextSettingsForm.controls['elementTextAlign'].setValue('right')"
                    class="ml-2"
                    matTooltip="{{ 'template.element.textAlignRight' | translate }}"
                    matTooltipPosition="below"
                  >
                    <mat-icon>format_align_right</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    (click)="elementTextSettingsForm.controls['elementTextAlign'].setValue('justify')"
                    class="ml-2"
                    matTooltip="{{ 'template.element.textAlignJustify' | translate }}"
                    matTooltipPosition="below"
                  >
                    <mat-icon>format_align_justify</mat-icon>
                  </button>
                </div>
                <!--              <div id="element-text-settings-vertical-align">-->
                <!--                <button-->
                <!--                  mat-icon-button-->
                <!--                  (click)="elementTextSettingsForm.controls['elementTextVerticalAlign'].setValue('start')"-->
                <!--                  class="ml-2"-->
                <!--                  matTooltip="{{ 'template.element.textAlignTop' | translate }}"-->
                <!--                  matTooltipPosition="below"-->
                <!--                >-->
                <!--                  <mat-icon>align_vertical_top</mat-icon>-->
                <!--                </button>-->
                <!--                <button-->
                <!--                  mat-icon-button-->
                <!--                  (click)="elementTextSettingsForm.controls['elementTextVerticalAlign'].setValue('center')"-->
                <!--                  class="ml-2"-->
                <!--                  matTooltip="{{ 'template.element.textAlignCenter' | translate }}"-->
                <!--                  matTooltipPosition="below"-->
                <!--                >-->
                <!--                  <mat-icon>align_vertical_center</mat-icon>-->
                <!--                </button>-->
                <!--                <button-->
                <!--                  mat-icon-button-->
                <!--                  (click)="elementTextSettingsForm.controls['elementTextVerticalAlign'].setValue('end')"-->
                <!--                  class="ml-2"-->
                <!--                  matTooltip="{{ 'template.element.textAlignBottom' | translate }}"-->
                <!--                  matTooltipPosition="below"-->
                <!--                >-->
                <!--                  <mat-icon>align_vertical_bottom</mat-icon>-->
                <!--                </button>-->
                <!--              </div>-->

                <ws-form-input
                  type="number"
                  formControlName="elementTextLineSpacing"
                  label="{{ 'template.element.textLineSpacing' | translate }}"
                ></ws-form-input>

                <ws-form-select
                  *ngIf="currentSelectedElement.instance.type !== ComponentType.DATASET"
                  label="{{ 'template.element.textWordBreak' | translate }}"
                  [options]="wordBreakOptions"
                  formControlName="elementTextWordBreak"
                ></ws-form-select>
              </div>
            </form>
          </mat-tab>
        </mat-tab-group>
      </div>
    </mat-sidenav>
  </mat-sidenav-container>
</div>
