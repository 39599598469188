<mat-dialog-content>
  <div class="flex flex-col">
    <h3 mat-dialog-title class="flex">{{ 'template.deleteTemplateDialog' | translate }}</h3>
    {{ this.data }}
  </div>
</mat-dialog-content>

<div class="flex flex-wrap justify-end">
  <mat-dialog-actions>
    <div class="px-4 py-3">
      <ws-button (click)="close()" [flat]="true">
        {{ 'template.close' | translate }}
      </ws-button>

      <ws-button (click)="save()" class="bg-accent text-white" [flat]="true">
        {{ 'save' | translate }}
      </ws-button>
    </div>
  </mat-dialog-actions>
</div>
