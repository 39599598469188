import {
  Component,
  ContentChild,
  TemplateRef,
  Input,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
  HostListener,
  inject
} from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { DialogAction } from '../../../types/notifications/dialog-action'
import { DialogOptions } from '../../../types/notifications/dialog-options'
import { Subject } from 'rxjs'

@Component({
  selector: 'ws-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit, OnDestroy {
  // at the top because translation is used in the input properties
  public translate: TranslateService = inject(TranslateService)

  /** The dialog title text which is displayed at the top of the dialog. If you want to use a template instead, use the dialogTitleTemplateRef input.*/
  @Input() dialogTitleText = ''
  /** The dialog content text which is displayed as middle part of the dialog. If you want to use a template instead, use the dialogContentTemplateRef input.*/
  @Input() dialogContentText = ''

  /**
   * List of dialog actions which are display at the bottom of the dialog. If you want to use a template instead, use the dialogActionsTemplateRef input.
   * The default actions are 'cancel' and 'confirm'. The clicked action will be emitted in the afterClose event.
   */
  @Input() dialogActions: DialogAction[] = [
    { label: this.translate.instant('ws.notifications.cancel'), action: 'cancel', buttonType: 'secondary' },
    { label: this.translate.instant('ws.notifications.confirm'), action: 'confirm', buttonType: 'primary' }
  ]
  /**
   * Dialog options to be passed to the dialog component.
   * Currently supported options:
   * - backdropDismiss: boolean (default: false) - whether the dialog should be closed when clicking outside of the dialog
   */
  @Input() dialogOptions: DialogOptions = {
    backdropDismiss: false
  }

  closeDialog = false
  dialogId = 'ws-dialog-' + Math.floor(Math.random() * 1000000)

  @ContentChild('dialogTitle', { static: true }) dialogTitleTemplateRef: TemplateRef<Element> | null = null
  @ContentChild('dialogContent', { static: true }) dialogContentTemplateRef: TemplateRef<Element> | null = null
  @ContentChild('dialogActions', { static: true }) dialogActionsTemplateRef: TemplateRef<Element> | null = null

  /** Subject which emits the action that was performed by the user.*/
  afterClose$ = new Subject<string>()

  public cdRf: ChangeDetectorRef = inject(ChangeDetectorRef)

  ngOnInit() {
    this.resetCurrentFocusedElement()
    this.registerEventListeners()
  }

  /** Prevents the dialog to stay open after closing it with a keyboard input instead of clicking*/
  resetCurrentFocusedElement() {
    const activeElement = document.activeElement as HTMLElement
    if (activeElement) {
      activeElement.blur()
    }
  }

  /**
   * Registers the event listeners for the dialog.
   * Currently only the click event is registered to close the dialog when clicking outside of the dialog.
   */
  registerEventListeners() {
    if (this.dialogOptions.backdropDismiss) {
      window.addEventListener('click', this.clickOutsideDialog)
    }
  }

  /**
   * Closes the dialog when clicking outside the dialog. This is only possible if the backdropDismiss option is set to true.
   * When clicking outside the dialog, the afterClose event will be emitted with the value 'dismissed'.
   */
  clickOutsideDialog = (event: any) => {
    if (event.target && event.target.id === this.dialogId) {
      this.close('dismissed')
    }
  }

  /** Closes the dialog and emits the given returnValue in the afterClose event.*/
  close(returnValue: string) {
    this.closeDialog = true
    this.afterClose$.next(returnValue)
    this.afterClose$.complete()
  }

  /** When the user presses the enter key, the dialog will be closed with the primary action.*/
  @HostListener('window:keydown.enter', ['$event']) handleEnterKeyboardEvent() {
    if (!this.dialogActionsTemplateRef && this.dialogActions.length > 0) {
      const primaryAction: DialogAction | undefined = this.dialogActions.find(
        (action: DialogAction): boolean => action.buttonType === 'primary'
      )
      if (primaryAction) {
        this.close(primaryAction.action)
      }
    }
  }

  /** When the user presses the escape key, the dialog will be closed with the secondary action.*/
  @HostListener('window:keydown.escape', ['$event']) handleEscapeKeyboardEvent() {
    if (!this.dialogActionsTemplateRef && this.dialogActions.length > 0) {
      const secondaryAction: DialogAction | undefined = this.dialogActions.find(
        (action: DialogAction): boolean => action.buttonType === 'secondary'
      )
      if (secondaryAction) {
        this.close(secondaryAction.action)
      }
    }
  }

  ngOnDestroy() {
    window.removeEventListener('click', this.clickOutsideDialog)
  }
}
