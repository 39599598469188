<mat-dialog-content>
  <div>
    <h3 class="flex font-bold mb-10">{{ 'template.editor.editTestData' | translate }}</h3>
    <ws-form-textarea
      label="{{ 'template.editor.testData.label' | translate }}"
      [rows]="15"
      class="w-full"
      [formControl]="testDataFormField"
    >
    </ws-form-textarea>
  </div>
</mat-dialog-content>

<div class="flex flex-wrap justify-end">
  <mat-dialog-actions>
    <div class="px-4 py-3">
      <ws-button (click)="close()" [flat]="true">
        {{ 'template.close' | translate }}
      </ws-button>

      <ws-button (click)="save()" class="bg-accent text-white" [flat]="true">
        {{ 'save' | translate }}
      </ws-button>
    </div>
  </mat-dialog-actions>
</div>
