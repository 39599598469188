<h1 mat-dialog-title>{{ 'template.create' | translate }}</h1>
<mat-dialog-content [formGroup]="createTemplateForm">
  <ws-form-input label="{{ 'template.name' | translate }}" formControlName="templateName"></ws-form-input>

  <!--  <div id="orientation" class="flex flex-col">-->
  <!--    <mat-label> {{ 'template.settings.orientation' | translate }} </mat-label>-->
  <!--    <mat-radio-group formControlName="size" aria-label="Select an option">-->
  <!--      <mat-radio-button value="{{ Orientation.PORTRAIT }}">{{-->
  <!--        'template.settings.portrait' | translate-->
  <!--      }}</mat-radio-button>-->
  <!--      <mat-radio-button value="{{ Orientation.LANDSCAPE }}">{{-->
  <!--        'template.settings.landscape' | translate-->
  <!--      }}</mat-radio-button>-->
  <!--    </mat-radio-group>-->
  <!--  </div>-->
</mat-dialog-content>

<div class="flex flex-wrap justify-end">
  <mat-dialog-actions>
    <div class="px-4 py-3">
      <ws-button (click)="close()" [flat]="true">
        {{ 'template.close' | translate }}
      </ws-button>

      <ws-button (click)="save()" class="bg-accent text-white" [flat]="true">
        {{ 'save' | translate }}
      </ws-button>
    </div>
  </mat-dialog-actions>
</div>
