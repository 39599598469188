import { Component, inject } from '@angular/core'
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar'
import { NotificationData } from '../../../types/notifications/notification-data'

@Component({
  selector: 'ws-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent {
  public snackBarRef: MatSnackBarRef<SnackbarComponent> = inject(MatSnackBarRef)
  public data: NotificationData = inject(MAT_SNACK_BAR_DATA)
}
