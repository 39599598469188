import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core'

export class CustomMissingTranslationHandler extends MissingTranslationHandler {
  private alreadyLogged: string[] = []

  public handle(params: MissingTranslationHandlerParams) {
    if (!this.alreadyLogged.includes(params.key) && params.key.includes('.') && !params.key.includes(' ')) {
      this.alreadyLogged.push(params.key)
    }
    return params.key
  }

  constructor() {
    super()
    this.logMissingTranslations()
    setInterval(this.logMissingTranslations, 5000)
  }

  private logMissingTranslations = () => {
    if (this.alreadyLogged.length > 0) {
      console.warn('Missing translations for keys: ', this.alreadyLogged)
      this.alreadyLogged = []
    }
  }
}
