import { NgModule } from '@angular/core'
import { InputComponent } from './input/input.component'
import { MatInputModule } from '@angular/material/input'
import { ToggleComponent } from './toggle/toggle.component'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CommonModule, NgOptimizedImage } from '@angular/common'
import { SelectComponent } from './select/select.component'
import { MatSelectModule } from '@angular/material/select'
import { BasicModelComponent } from './basic-model/basic-model.component'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { MatButtonModule } from '@angular/material/button'
import { CheckboxComponent } from './checkbox/checkbox.component'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { TextareaComponent } from './textarea/textarea.component'
import { SliderComponent } from './slider/slider.component'
import { MatSliderModule } from '@angular/material/slider'
import { ButtonToggleComponent } from './button-toggle/button-toggle.component'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatNativeDateModule } from '@angular/material/core'
import { SimpleInputComponent } from './simple-input/simple-input.component'
import { MatIconModule } from '@angular/material/icon'
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field'
import { FieldComponent } from './field/field.component'
import { SimpleUploadComponent } from './simple-upload/simple-upload.component'
import { ProfilePictureUploadComponent } from './profile-picture-upload/profile-picture-upload.component'
import { DatepickerComponent } from './datepicker/datepicker.component'
import { ImageCropperComponent } from './image-cropper/image-cropper.component'
import { MatDialogModule } from '@angular/material/dialog'
import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop'
import { OptionsFieldComponent } from './options-field/options-field.component'
import { ChipsSelectComponent } from './chips-select/chips-select.component'
import { MatChipsModule } from '@angular/material/chips'
import { TranslationTabsComponent } from './translation-tabs/translation-tabs.component'
import { MatTabsModule } from '@angular/material/tabs'
import { AddressInputComponent } from './address-input/address-input.component'
import { SpeechRecognitionDialogComponent } from './speech-recognition-dialog/speech-recognition-dialog.component'
import { WsNotificationsModule } from '../notifications/ws-notifications.module'
import { ColorPickerComponent } from './color-picker/color-picker.component'
import { MatMenuModule } from '@angular/material/menu'
import { TextEditorComponent } from './text-editor/text-editor.component'
import { MatTooltipModule } from '@angular/material/tooltip'
import { ChipsInputComponent } from './chips-input/chips-input.component'
import { EmojiInsertComponent } from './emoji-insert/emoji-insert.component'
import { TimepickerComponent } from './timepicker/timepicker.component'
import { WsIconComponent } from '../ws-icon/ws-icon.component'
import { ClipboardModule } from '@angular/cdk/clipboard'
import { NGX_EDITOR_CONFIG_TOKEN, NgxEditorModule, NgxEditorService } from 'ngx-editor'
import { TextEditorNewComponent } from './text-editor-new/text-editor-new.component'
import { DatetimePickerComponent } from './datetime-picker/datetime-picker.component'
import { AdvancedFileUploadComponent } from './advanced-file-upload/advanced-file-upload.component'
import { WsButtonComponent } from '../ws-button/ws-button.component'
import { ngxEditorConfigFactory } from './text-editor-new/ngx-editor-config-factory'
import { CustomFloatingMenuComponent } from './text-editor-new/custom-floating-menu/custom-floating-menu.component'
import { CustomEditorMenuComponent } from './text-editor-new/custom-editor-menu/custom-editor-menu.component'
import { ImageFileToObjectUrlPipe } from '../pipes/image-file-to-object-url.pipe'
import { FileSizeFormatPipe } from '../pipes/file-size-format.pipe'
import { FilePreviewComponent } from './file-preview/file-preview.component'
import { ArrayIncludesPipe } from '../pipes/array-includes.pipe'
import { StringIncludesPipe } from '../pipes/string-includes.pipe'
import { WsPipesModule } from '../pipes/ws-pipes.module'

@NgModule({
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    {
      useFactory: ngxEditorConfigFactory,
      provide: NGX_EDITOR_CONFIG_TOKEN,
      deps: [TranslateService]
    },
    NgxEditorService
  ],
  declarations: [
    InputComponent,
    TextareaComponent,
    ToggleComponent,
    SelectComponent,
    BasicModelComponent,
    CheckboxComponent,
    SliderComponent,
    ButtonToggleComponent,
    SimpleInputComponent,
    FieldComponent,
    SimpleUploadComponent,
    AdvancedFileUploadComponent,
    ProfilePictureUploadComponent,
    DatepickerComponent,
    ImageCropperComponent,
    OptionsFieldComponent,
    ChipsSelectComponent,
    TranslationTabsComponent,
    AddressInputComponent,
    TranslationTabsComponent,
    SpeechRecognitionDialogComponent,
    ColorPickerComponent,
    TextEditorComponent,
    TextEditorNewComponent,
    CustomFloatingMenuComponent,
    CustomEditorMenuComponent,
    ChipsInputComponent,
    TimepickerComponent,
    DatetimePickerComponent,
    TimepickerComponent,
    AdvancedFileUploadComponent,
    FilePreviewComponent
  ],
  imports: [
    MatNativeDateModule,
    MatInputModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatSelectModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en'
    }),
    MatButtonModule,
    MatCheckboxModule,
    MatSliderModule,
    MatButtonToggleModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatIconModule,
    NgOptimizedImage,
    MatDialogModule,
    CdkDrag,
    MatChipsModule,
    MatTabsModule,
    WsNotificationsModule,
    MatMenuModule,
    WsIconComponent,
    ClipboardModule,
    WsButtonComponent,
    MatTooltipModule,
    EmojiInsertComponent,
    ImageFileToObjectUrlPipe,
    FileSizeFormatPipe,
    WsIconComponent,
    ClipboardModule,
    NgxEditorModule,
    NgxEditorModule.forRoot(),
    ArrayIncludesPipe,
    StringIncludesPipe,
    WsPipesModule,
    CdkDropList
  ],
  exports: [
    InputComponent,
    TextareaComponent,
    ToggleComponent,
    SelectComponent,
    CheckboxComponent,
    BasicModelComponent,
    SliderComponent,
    ButtonToggleComponent,
    SimpleInputComponent,
    SimpleUploadComponent,
    AdvancedFileUploadComponent,
    ProfilePictureUploadComponent,
    ImageCropperComponent,
    DatepickerComponent,
    ChipsSelectComponent,
    ChipsInputComponent,
    TranslationTabsComponent,
    AddressInputComponent,
    ColorPickerComponent,
    TextEditorComponent,
    TextEditorNewComponent,
    CustomFloatingMenuComponent,
    CustomEditorMenuComponent,
    TimepickerComponent,
    AdvancedFileUploadComponent,
    TimepickerComponent,
    DatetimePickerComponent,
    FilePreviewComponent
  ]
})
export class WsFormsModule {}
