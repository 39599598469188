import { Component, Inject, inject, Input, OnDestroy, OnInit } from '@angular/core'
import { FieldComponent } from '../field/field.component'
import { ControlValueAccessor, FormControl, NgControl, ValidationErrors, ValidatorFn } from '@angular/forms'
import { BehaviorSubject, Subscription } from 'rxjs'
import { Translations } from '../../../types/forms/translations'
import { WsValidators } from '../../../types/forms/ws-validators'
import { WsFormsService } from '../ws-forms.service'
import { AbstractPlatformService } from '../../../types/module-view/abstract-platform-service'

@Component({
  selector: 'ws-form-translation-tabs',
  templateUrl: './translation-tabs.component.html',
  styleUrls: ['./translation-tabs.component.scss']
})
export class TranslationTabsComponent extends FieldComponent implements ControlValueAccessor, OnInit, OnDestroy {
  public override value: Translations = {}

  @Input() languages: string[] = ['de', 'en', 'it', 'fr', 'nl']
  @Input() selectedLanguage = ''
  @Input() autofocus = false
  @Input() hideTabHeader = false
  @Input() group?: string
  @Input() tag: 'input' | 'textarea' = 'input'
  /** If true, the textarea will automatically grow vertically as the user uses more rows */
  @Input() autosize = false
  /** The minimum number of rows to display if using textarea */
  @Input() rows = 5
  @Input() inputDisabled = false

  translationTabGroupSubject?: BehaviorSubject<string>

  selectedTabIndex = 0

  singleInputFormControl = new FormControl('')
  singleInputChange?: Subscription

  translationValidators: ValidatorFn | null = null

  public wsFormsService: WsFormsService = inject(WsFormsService)
  constructor(
    public override ngControl: NgControl,
    @Inject('PlatformService') public override platformService: AbstractPlatformService
  ) {
    super(ngControl, platformService)
  }

  ngOnInit() {
    setTimeout(() => {
      this.translationValidators = this.formControl.validator
      this.formControl.clearValidators()
      // this.formControl.setErrors(null)

      this.selectedLanguage = this.languages[0]
      if (this.selectedLanguage) {
        this.selectedTabIndex = this.languages.indexOf(this.selectedLanguage)
      }
      this.handleTabLanguageChange(this.selectedTabIndex)

      if (this.inputDisabled) {
        this.singleInputFormControl.disable()
        return
      }

      this.subscribeToTranslationGroup()

      this.singleInputChange = this.singleInputFormControl.valueChanges.subscribe((input: string | null) => {
        this.handleTabInputChange(input)
      })
    })
  }

  subscribeToTranslationGroup() {
    if (!this.group) {
      return
    }

    this.translationTabGroupSubject = this.wsFormsService.getTranslationGroupSubject(this.group, this.selectedLanguage)

    this.translationTabGroupSubject.subscribe((language) => {
      this.selectedLanguage = language
      this.selectedTabIndex = this.languages.indexOf(language)
    })
  }

  private getErrorsForInput(input: string): ValidationErrors | null {
    let errors: ValidationErrors | null = null

    if (this.translationValidators) {
      const tempFormControl = new FormControl(input, this.translationValidators)
      tempFormControl.markAsTouched()
      errors = tempFormControl.errors

      // specially handle required validator because only one translation is required
      if (tempFormControl.hasError('required')) {
        const hasAnyInput = Object.values(this.value).filter((value) => !!value).length > 0

        if (hasAnyInput) {
          const newErrors = { ...tempFormControl.errors }
          delete newErrors['required']
          if (Object.values(newErrors).length === 0) {
            errors = null
          } else {
            errors = newErrors
          }
        }
      }
    }

    return errors
  }

  setErrorsOnInput() {
    const currentInput = this.value ? this.value[this.selectedLanguage] : ''
    const errorsForCurrentInput = this.getErrorsForInput(currentInput)
    this.singleInputFormControl.setErrors(errorsForCurrentInput)
    if (errorsForCurrentInput && errorsForCurrentInput['required']) {
      this.singleInputFormControl.setValidators(this.translationValidators)
    } else {
      this.singleInputFormControl.clearValidators()
    }
  }

  setErrorsOnComponent() {
    let errorsToEmit: ValidationErrors | null = null
    for (const language of this.languages) {
      const languageInput = this.value ? this.value[language] : ''
      const errorsForCurrentInput = this.getErrorsForInput(languageInput)

      if (!errorsForCurrentInput) {
        continue
      }

      if (errorsToEmit) {
        Object.assign(errorsToEmit, errorsForCurrentInput)
      } else {
        errorsToEmit = errorsForCurrentInput
      }
    }

    if (errorsToEmit && errorsToEmit['required']) {
      this.formControl.setValidators([WsValidators.objectValueRequired()])
    } else {
      this.formControl.clearValidators()
    }
    this.formControl.setErrors(errorsToEmit)

    this.formControl.updateValueAndValidity()
  }

  override ngOnDestroy() {
    super.ngOnDestroy()
    this.singleInputChange?.unsubscribe()
  }

  handleTabInputChange = (input: string | null) => {
    this.onTouched()

    if (input === null) {
      delete this.value[this.selectedLanguage]
    } else {
      this.value = { ...this.value, [this.selectedLanguage]: input }
    }
    this.onFieldChange(this.value)
  }

  public override onFieldChange(input: Translations) {
    // this.setErrorsOnInput()
    this.setErrorsOnComponent()
    super.onFieldChange(input)
  }

  handleTabLanguageChange(tabIndex: number) {
    if (!this.value) {
      return
    }

    this.selectedLanguage = this.languages[tabIndex]

    this.translationTabGroupSubject?.next(this.selectedLanguage)

    if (!(this.selectedLanguage in this.value)) {
      this.value = { ...this.value, [this.selectedLanguage]: '' }
      this.onFieldChange(this.value)
    }
    this.singleInputFormControl.setValue(this.value[this.selectedLanguage])
  }

  override writeValue(value: Translations) {
    super.writeValue(value)
    if (value) {
      this.singleInputFormControl.setValue(this.value[this.selectedLanguage])
    }
  }
}
